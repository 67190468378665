import { forwardRef } from 'react';
import Select from 'react-select';
import classes from './SelectInput.module.css';

const SelectInput = forwardRef(
  (
    {
      onChange,
      options,
      name,
      value = undefined,
      className,
      register,
      validation,
      placeholder,
    },
    ref
  ) => {
    const reg = register ? { ...register(name, { ...validation }) } : null;

    
// const customStyles = {
//   control: (provided,state) => ({
//     ...provided,
//     width: "200px",
//     fontSize:"14px",
//     border: '1px solid #ccc', // Example border style, adjust as needed
//     borderRadius: '4px', // Example border radius, adjust as needed
//     backgroundColor: '#eee',
//     fontWeight: "bold",
//     cursor: "pointer",
//     border: 'none', // Remove the border
//     boxShadow: state.isFocused ? '0 0 0 2px #007bff' : 'none',
//     // Add other custom styles here
//   }),
//   option: (provided) => ({
//     ...provided,
//     fontSize: '14px', // Adjust the font size as needed

//     // Add other styles for the option items here
//   }),
//   menu: (provided, state) => ({
//     ...provided,

   
    
//   }),
//   // Add more custom styles for other components as needed
// };

const selectOptions = options.map((opt) => ({
  value: opt,
  label: opt,
}));

const formattedOptions = selectOptions.map((option, index) => ({
  ...option,
  isFirstOption: index === 0,
}));

const customStyles = {
  control: (provided, state) => {
    console.log('Control State:', state);

    return {
      ...provided,
      width: '200px',
      fontSize: '14px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: '#eee',
      fontWeight: 'bold',
      cursor: 'pointer',
      border: 'none',
      boxShadow: state.isFocused ? '0 0 0 2px #007bff' : 'none',
    };
  },
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    // Add other styles for the option items here
  }),
  menu: (provided, state) => {
    console.log('Menu State:', state);
    const hasRegionOption = state.options.some((item) => item.value === 'Region');

    // Set background color based on the condition
    const backgroundColor = hasRegionOption ? 'red' : '#eee';
    return {
      ...provided,
      // Add other menu styles here
    };
  },
  // Add more custom styles for other components as needed
};

   

    return (
      <Select
      options = {selectOptions}
        
        name={name}
        ref={ref}
        onChange={onChange}
        className={`${classes.selectInput} ${className}`}
        {...reg}
        // value={value}
        menuPortalTarget={document.body}
        styles = {customStyles}
        components={{
          IndicatorSeparator: () => null, //hide vertical stick
        }}
        placeholder = {placeholder}
      />
     
    );
  }
);

export default SelectInput;
