import { API_BASE_URL } from "configs/AppConfig";


const makePayment = async (body, user) => {
  console.log("entered into function");

  const response = await fetch(`${API_BASE_URL}payment/phonepe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.sessionToken}`,
    },
    body: JSON.stringify(body),
  });

  const data = await response.json();
  // if (!response.ok) {
  //   setNotifyMessage(data.message, "error");
  //   throw new Error(data.message);
  // }
  // setNotifyMessage("Payment done", "success");
  console.log("payment data", data);
  return data;
};

export default makePayment;
