import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { modalActions } from '../store-redux/modal-slice';

const DispatchSignInModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    navigate('/');
    dispatch(
      modalActions.changeModalComponent({
        modalType: 'signin'
      })
    );
  }, []);
  return <></>;
};

export default DispatchSignInModal;
