import { Link } from 'react-router-dom';

import classes from './NavbarLogo.module.css';




const NavbarLogoDashboard = ({ onClick }) => {
    return (
      <Link to="/" onClick={onClick}>
        <img
          src="/img/logo-navbar.svg"
          alt="Logo"
          loading="lazy"
          className={classes.navbarLogo}
        />
      </Link>
    );
  };
  
  export default NavbarLogoDashboard;