import React, { useEffect, useState } from "react";
import classes from "./Securitydeposit.module.css";
import ModalHeader from "components/UI/modal/ModalHeader";
import Modal from "components/UI/modal/Modal";
import Button from "components/UI/button/Button";
import makePayment from "./MakePayment";
import { useSelector } from "react-redux";
import useNotificationBar from "hooks/useNotificationBar";
import { APP_PREFIX_PATH } from "configs/AppConfig";

function SecurityDepisitModal({ onClose }) {
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { setNotifyMessage, navigationComponent } = useNotificationBar();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  console.log("path name", window.location);

  const createPayment = async () => {
    const expiryTime = new Date().getTime() + 15 * 60 * 1000;
    const redirectObject = {
      type: "security_deposit",
      url: `${APP_PREFIX_PATH}dashboard/online-auctions`,
      expiryTime: expiryTime,
    };
    localStorage.setItem("redirect_object", JSON.stringify(redirectObject));
    setLoading(true);
    const body = {
      amount: Number(amount),
      transactionData: {
        type: "SECURITY_DEPOSIT",
      },
    };

    const response = await makePayment(body, user);
    if (response.Url) {
      window.location.href = response.Url;
    }
    if (response.error) {
      setNotifyMessage(response.error.message, "error");
    }
    setLoading(false);
  };

  return (
    <>
      {navigationComponent}
      <Modal onClose={onClose} className={classes.modal}>
        <ModalHeader
          className={classes.header}
          onClose={onClose}
          title="Add Security Deposit"
        />
        <div className={classes.outerWrapper}>
          <input
            type="number"
            placeholder = "Enter Security Deposit"
            onChange={(e) => setAmount(e.target.value)}
          />
          <Button
            loading={loading}
            disabled={loading}
            onClick={createPayment}
            option="colored"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default SecurityDepisitModal;
