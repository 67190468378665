import React from 'react'
import AuctionsList from './Auctions/Auctions';
import Navbar from 'components/page-content/dashboard-content/navbar/Navbar';


function Tokens() {
    
  return (
    <div>
        <Navbar />
      <AuctionsList />
    </div>
  )
}

export default Tokens
