import Modal from "components/UI/modal/Modal";
import ModalHeader from "components/UI/modal/ModalHeader";
import React from "react";
import classes from "./AuctionToken.module.css";

function AuctionToken({
  onClose,
  name,
  auctionNo,
  userName,
  phoneNumber,
  tokenNumber,
  createdAt,
}) {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal onClose={onClose} className={classes.modal}>
      <ModalHeader className={classes.header} onClose={onClose} title="" />
      <div className={classes.modalOuter}>
        <div className={classes.leftDiv}>
          <div className={classes.imageContainer}>
            <img src="/img/vehiclemall-logo.svg" />
          </div>
          <div className={classes.auctionTitle}>
            <h1>Name :</h1>
            <h2>&nbsp;{userName}</h2>
          </div>
          <div className={classes.auctionTitle}>
            <h1>Date :</h1>
            <h2>&nbsp;{new Date(+createdAt).toLocaleString('en-uk', {month: 'short',day: 'numeric',year: 'numeric'})}</h2>
          </div>
          <div className={classes.auctionTitle}>
            <h1>Number :</h1>
            <h2>&nbsp;{phoneNumber.slice(0,3)+'-'+phoneNumber.slice(3)}</h2>
          </div>
          <div className={classes.auctionTitle}>
            <h1>Location :</h1>
            <h2>&nbsp;Bangalore</h2>
          </div>
        </div>
        <div className={classes.rightDiv}>
          <div className={classes.tokenContainer}>
            <h2>Token</h2>
            <h3>{tokenNumber}</h3>
          </div>
          <div className={classes.nameSection}>
            <h2>Auction name :</h2>
            <h3>{name}</h3>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AuctionToken;
