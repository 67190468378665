const NotificationIcon = ({ className, onClick }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M16.9777 13.0826C16.6918 13.0826 16.4591 12.85 16.4591 12.564V8.90074C16.4591 6.00853 14.5483 3.55428 11.9229 2.73393C12.0018 2.51413 12.0435 2.28078 12.0435 2.04335C12.0435 0.916663 11.1269 0 10.0001 0C8.87332 0 7.9567 0.916663 7.9567 2.04339C7.9567 2.28267 7.99757 2.51566 8.07598 2.7344C5.4512 3.55514 3.54104 6.00904 3.54104 8.90078V12.5641C3.54104 12.85 3.3084 13.0827 3.02247 13.0827C1.87898 13.0827 0.94873 14.0129 0.94873 15.1564C0.94873 16.2999 1.87898 17.2301 3.02247 17.2301H7.86014C7.81342 17.4104 7.78896 17.5977 7.78896 17.7889C7.78896 19.0081 8.78089 20 10.0001 20C11.2193 20 12.2112 19.0081 12.2112 17.7889C12.2112 17.5978 12.1868 17.4104 12.14 17.2301H16.9777C18.1212 17.2301 19.0514 16.2999 19.0514 15.1564C19.0515 14.0129 18.1212 13.0826 16.9777 13.0826ZM9.13462 2.04339C9.13462 1.56617 9.52287 1.17793 10.0001 1.17793C10.4773 1.17793 10.8656 1.56617 10.8656 2.04339C10.8656 2.19986 10.8232 2.35216 10.7445 2.48488C10.5002 2.45673 10.2518 2.44173 10.0001 2.44173C9.7486 2.44173 9.50057 2.45669 9.25646 2.4848C9.17762 2.35248 9.13462 2.20139 9.13462 2.04339ZM11.0333 17.7889C11.0333 18.3586 10.5698 18.8221 10.0001 18.8221C9.43044 18.8221 8.96693 18.3586 8.96693 17.7889C8.96693 17.5876 9.02433 17.3952 9.13093 17.2301H10.8693C10.9759 17.3952 11.0333 17.5876 11.0333 17.7889ZM16.9777 16.0522H3.02247C2.52853 16.0522 2.12666 15.6503 2.12666 15.1564C2.12666 14.6624 2.52853 14.2605 3.02247 14.2605C3.9579 14.2605 4.71896 13.4995 4.71896 12.564V8.90074C4.71896 5.9887 7.08805 3.61962 10.0001 3.61962C12.9121 3.61962 15.2812 5.9887 15.2812 8.90074V12.564C15.2812 13.4995 16.0423 14.2605 16.9777 14.2605C17.4716 14.2605 17.8735 14.6624 17.8735 15.1564C17.8736 15.6503 17.4716 16.0522 16.9777 16.0522Z"
      />
    </svg>
  );
};

export default NotificationIcon;
