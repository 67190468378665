import { useRef, useState } from 'react';

import { emailIsValid } from 'validators/authValidation';

import Button from 'components/UI/button/Button';
import MailIcon from 'components/UI/icons/MailIcon';
import InputWithLabel from 'components/UI/forms/input/InputWithLabel';
import CheckboxInput from 'components/UI/forms/input/CheckboxInput';

import classes from './SignupForm.module.css';
import ErrorLabel from 'components/UI/forms/errors/ErrorLabel';

const SignupForm = ({ onSumbitForm }) => {
  const [formError, setFormError] = useState(null);

  const emailInputRef = useRef();
  const acceptTermsInputRef = useRef();

  const onSumbitFormHandler = (event) => {
    event.preventDefault();

    if (!emailIsValid(emailInputRef.current.value)) {
      setFormError('Email is invalid');
      return;
    }

    if (!acceptTermsInputRef.current.checked) {
      setFormError('Must agree');
      return;
    }

    onSumbitForm(emailInputRef.current.value);
  };

  return (
    <form className={classes.signupForm} onSubmit={onSumbitFormHandler}>
      {formError && <ErrorLabel> {formError} </ErrorLabel>}
      <InputWithLabel
        labelText="Email"
        name="email"
        type="email"
        ref={emailInputRef}
        icon={MailIcon}
        required
      />
      <CheckboxInput
        id="acceptTerms"
        name="acceptTerms"
        ref={acceptTermsInputRef}
        isCircle
        label="I agree with terms &amp; conditions"
      />
      <Button type="sumbit" option="colored" className={classes.sumbitButton}>
        Sign Up
      </Button>
    </form>
  );
};

export default SignupForm;
