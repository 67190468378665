import Button from "../Button";

import classes from "./AuctionTimesBtns.module.css";

const AuctionTimesBtnsTokens = ({
  numberOfActiveBtn,
  onClick1,
  onClick2,
  onClick3,
  className,
}) => {
  return (
    <div className={`${classes.timesBtns} ${className}`}>
      <Button
        type="button"
        option={numberOfActiveBtn === 1 ? "colored" : "uncolored"}
        onClick={onClick1}
      >
        Live Auctions
      </Button>
      <Button
        type="button"
        option={numberOfActiveBtn === 2 ? "colored" : "uncolored"}
        onClick={onClick2}
      >
        Upcoming Auctions
      </Button>
      <Button
        type="button"
        option={numberOfActiveBtn === 3 ? "colored" : "uncolored"}
        onClick={onClick3}
      >
        Completed Auctions
      </Button>
      {/* {window.location.pathname.includes('/physical-bidding') && (
      <Button
        type="button"
        option={numberOfActiveBtn === 2 ? "colored" : "uncolored"}
        onClick={onClick2}
      >
        Tokens
      </Button>
      )} */}
      {/* <Button
        type="button"
        option={numberOfActiveBtn === 3 ? 'colored' : 'uncolored'}
        onClick={onClick3}
      >
        Completed Auctions
      </Button> */}
    </div>
  );
};

export default AuctionTimesBtnsTokens;
