import { useNavigate } from "react-router";
import { API_BASE_URL } from "../../../../configs/AppConfig";

import classes from "./AuctionItem.module.css";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import PhysicalAuctionPayment from "components/page-content/dashboard-content/dashboard-ui/auctions/PhysicalAuctionPayment/PhysicalAuctionPayment";
import Blink from "react-blink-text";
import { auctionActions } from "store-redux/auction-slice";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import Button from "components/UI/button/Button";
import AuctionToken from "../AuctionToken";
import AuctionToken2 from "../AuctionToken2";

const vehicleTypeImg = [
  {
    image: "img/vehicleTypes/Group-35327.svg",
  },
  {
    image: "img/vehicleTypes/Group-35328.svg",
  },
  {
    image: "img/vehicleTypes/Group-35329.svg",
  },
  {
    image: "img/vehicleTypes/Group-35330.svg",
  },
  {
    image: "img/vehicleTypes/Group-35331.svg",
  },
  {
    image: "img/vehicleTypes/Group-35332.svg",
  },
];

const AuctionItems = ({
  id,
  name,
  vehicleCount,
  date,
  imgSrc,
  description,
  vehicleType,
  registrationPrice,
  notInListHandler,
  auctionNo,
  startTimestamp,
  endTimestamp,
  tokenNumber,
  createdAt,
  mobileNumber,
}) => {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const [buttonDisable, setButtonDisable] = useState(false);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const { isLoading, mutateAsync: checkBuyerInAuction } = useMutation(
    async () => {
      const response = await fetch(
        `${API_BASE_URL}auction/${id}/public`,
        // `${API_BASE_URL}auction_request/get/all?auctionId=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );

      const data = await response.json();
      return data;
    }
  );

  const tokenViewHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: `viewToken${id}`,
        // modalType: `vehicleimageview${InventoryNo}`
      })
    );
  };

  const onClickViewButtonHandler = async () => {
    setButtonDisable(true);
    console.log("entered into function");

    if (!isLoggedIn) {
      setSigninModalHandler();
      return;
    }

    const auctionData = await checkBuyerInAuction(); //to check in auction data, buyer is allowed to view auction
    console.log("auctionData ", auctionData);
    if (auctionData) {
      dispatch(
        auctionActions.updateAuctionData({
          auctionData: auctionData,
        })
      );
      const auctionBuyers = await auctionData?.data?.buyers?.map(
        (buyer) => buyer._id
      );

      if (!auctionBuyers?.includes(user.user?._id)) {
        notInListHandler();
        setButtonDisable(false);
      } else {
        console.log("already in auction");
        if (
          window.location.pathname.includes("/dashboard/physical-bidding") ||
          window.location.pathname.includes("/dashboard/tokens")
        ) {
          navigate(`/dashboard/physical-bidding/${id}`);
          setButtonDisable(false);
        } else {
          navigate(`/physical-bidding/${id}`);
          setButtonDisable(false);
        }
      }
    }

    // if (window.location.pathname.includes("/dashboard/physical-bidding")) {
    //   navigate(`/dashboard/physical-bidding/${id}`);
    // } else {
    //   navigate(`/physical-bidding/${id}`);
    // }
  };

  const participateHandler = () => {
    if (isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: `participateModal${id}`,
          // modalType: `vehicleimageview${InventoryNo}`
        })
      );
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const toggleModalHandler = () => {
    // toggle the visibility of the Modal
    dispatch(modalActions.toggleModal());
    //to set input element value to buyer last bid
  };

  const onClickInterestedButtonHandler = () => {};

  console.log("startTimestamp", startTimestamp, new Date().getTime());

  const dateString = new Date(+date).toDateString();
  const timeString = new Date(+date).toLocaleTimeString();
  return (
    <>
      <li className={classes.auctionItem}>
        <img src={imgSrc} alt="auction" loading="lazy" />
        <section className={classes.info}>
          <div className={classes.liveOuterDiv}>
            <h1> {name} </h1>
            {(!(startTimestamp > new Date().getTime()) && (endTimestamp > new Date().getTime())) && (
              <div className={classes.rightSection}>
                <div className={classes.liveCircle}></div>
                <Blink
                  color="#FF0000"
                  text="LIVE"
                  fontSize="20px"
                  fontWeight="600"
                ></Blink>
              </div>
            )}
          </div>
          <h4> Available Vehicle: {vehicleCount} </h4>
          <p>
            {timeString}, {dateString}
          </p>
          {/* <p> {description ? Object.values(description).join(', ') : ''}</p> */}
          <section className={classes.category}>
            <Button type="button" option="colored">
              Category
            </Button>
            {/* {vehicleType?.map((type) => (
              <img src={type.image} alt="type" loading="lazy" />
            ))} */}
          </section>
        </section>
        <section className={classes.btns}>
          <Button
            type="button"
            option="uncolored"
            onClick={onClickViewButtonHandler}
            loading={buttonDisable}
            disabled={buttonDisable}
          >
            View Now
          </Button>
          {/* <Button
            type="button"
            option="uncolored"
            onClick={tokenViewHandler}
            loading = {buttonDisable}
            disabled = {buttonDisable}
          >
            View Token
          </Button> */}
          <Button
            type="button"
            option="colored"
            // onClick={onClickInterestedButtonHandler}
            onClick={() => {
              tokenViewHandler();
            }}
          >
            View Token
          </Button>
        </section>
      </li>
      {modalIsVisible && modalType === `participateModal${id}` && (
        <PhysicalAuctionPayment
          onClose={toggleModalHandler}
          price={registrationPrice}
          name={name}
          auctionNo={auctionNo}
          auctionId={id}
        />
      )}

      {modalIsVisible && modalType === `viewToken${id}` && (
        <AuctionToken
          name={name}
          auctionNo={auctionNo}
          userName={user.user.username}
          phoneNumber={mobileNumber}
          onClose={toggleModalHandler}
          tokenNumber={tokenNumber}
          createdAt = {createdAt}
        />
      )}
    </>
  );
};

export default AuctionItems;
