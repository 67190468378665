import { useState, useCallback, useEffect } from 'react';

import NotificationBar from '../components/UI/notfication-bar/NotificationBar';

let timer = null;
const useNotificationBar = () => {
  const [notifyMessage, setNotifyMessage] = useState(null);

  useEffect(() => {
    if (!!notifyMessage) {
      timer = setTimeout(() => setNotifyMessage(null), 2000);
    }

    return () => clearTimeout(timer);
  }, [notifyMessage]);

  const setNotifyMessageHanlder = useCallback((msg, type) => {
    setNotifyMessage({ msg, type });
  }, []);

  return {
    setNotifyMessage: setNotifyMessageHanlder,
    navigationComponent: notifyMessage ? (
      <NotificationBar type={notifyMessage.type} msg={notifyMessage.msg} />
    ) : null
  };
};

export default useNotificationBar;
