import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "store-redux/user-slice";

import { NavbarSmallLinks } from "helpers/constants";

import StarIcon from "components/UI/icons/StartIcon";
import NotificationIcon from "components/UI/icons/NotificationIcon";
import CarListIcon from "components/UI/icons/CarListIcon";

import classes from "./NavbarListIcon.module.css";
import NavbarItem from "../navbar-list/navbar-item/NavbarItem";
import Backdrop from "components/UI/modal/Backdrop";
import { modalActions } from "store-redux/modal-slice";
import SecurityDepisitModal from "components/page-content/profile-content/user-profile/SecurityDepisitModal";

const NavbarListIcon = ({ className, setSigninModalHandler }) => {
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const navigate = useNavigate();

  const [isListModalVisable, setIsListModalVisable] = useState(false);
  const [flag, setFlag] = useState(true);

  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { user: profile } = user;
  const dispatch = useDispatch();

  const setIsListModalVisableHandler = () => {
    setIsListModalVisable((prevIsListModalVisable) => !prevIsListModalVisable);
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    navigate("/");
  };

  const onWatchlistClick = () => {
    if (!isLoggedIn && user.user.participantType !== "Buyer") {
      setSigninModalHandler();
      return;
    }
    navigate("/dashboard/watchlist/inventory");
  };

  const transactionHandle = () => {
    if (isLoggedIn) {
      navigate("dashboard/transactions");
      setIsListModalVisable(false);
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const invoiceHandler = () => {
    if (isLoggedIn) {
      navigate("dashboard/invoices");
      setIsListModalVisable(false);
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const toggleModalHandler = async () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
    // const data = await fetchUserData(user.sessionToken);
    // if (data) {
    //   dispatch(userActions.updateUser({ user: data.data }));
    //   // setProfile(data.data);
    // }
  };

  const depositHandle = async () => {
    setIsListModalVisable(false);

    dispatch(
      modalActions.changeModalComponent({
        modalType: "securityDeposit",
      })
    );
  };

  console.log("modal visible", modalIsVisible, isListModalVisable, modalType);

  return (
    isLoggedIn && (
      <div className={`${classes.navbarListIcon} ${className}`}>
        <StarIcon onClick={onWatchlistClick} />
        <NotificationIcon />
        <CarListIcon
          className={classes.carIcon}
          onClick={setIsListModalVisableHandler}
        />
        {isListModalVisable && (
          <>
            <div className={classes.smallListModal}>
              <ul>
                {NavbarSmallLinks.map((link, index) => (
                  <NavbarItem
                    key={link.key ? link.key : link.to + index}
                    pageLink={link.to}
                    onClick={setIsListModalVisableHandler}
                  >
                    {link.title}
                  </NavbarItem>
                ))}
                <li onClick={transactionHandle}> Transactions </li>
                <li onClick={invoiceHandler}> Invoices </li>
                <li onClick={depositHandle}> Security Deposit </li>
                <li onClick={onClickSignoutHandler}> Sign out </li>
              </ul>
              {/* <img src={user.img} loading='lazy' alt='profile' /> */}
              <img src="/img/profileIcon.svg" loading="lazy" alt="profile" />
            </div>
            <Backdrop onClose={setIsListModalVisableHandler} transparent />
            {/* {modalIsVisible && modalType === "securityDeposit" && (
              <SecurityDepisitModal
                onClose={toggleModalHandler}
                amount={profile.wallet.securityDeposit}
              />
            )} */}
          </>
        )}
      </div>
    )
  );
};

export default NavbarListIcon;
