import React from "react";
import classes from "./PhysicalAuctionPayment.module.css";
import ModalHeader from "components/UI/modal/ModalHeader";
import Modal from "components/UI/modal/Modal";
import "./Auctions.css";
import { API_BASE_URL } from "configs/AppConfig";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import useNotificationBar from "hooks/useNotificationBar";
import { useNavigate } from "react-router-dom";
import Button from "components/UI/button/Button";
import makePayment from "components/page-content/profile-content/user-profile/MakePayment";

function PhysicalAuctionPayment({
  onClose,
  price,
  name,
  auctionNo,
  auctionId,
}) {
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { setNotifyMessage, navigationComponent } = useNotificationBar();

  const expiryTime = new Date().getTime() + 15 * 60 * 1000;

  const redirectObject = {
    type: "token",
    url: window.location.pathname,
    expiryTime: expiryTime,
  };
  console.log("redirect object", redirectObject);

  const navigate = useNavigate();

  const { isLoading, mutateAsync: createPayment } = useMutation(async () => {
    console.log("entered into function");
    localStorage.setItem("redirect_object", JSON.stringify(redirectObject));
    // const response = await fetch(`${API_BASE_URL}payment/phonepe`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${user.sessionToken}`,
    //   },
    //   body: JSON.stringify({
    //     amount: price,
    //     transactionData: {
    //       // package: "string",
    //       auction: auctionId,
    //       // inventories: ["string"],
    //       type: "TOKEN",
    //       imageInspectionFormat: "Single",
    //       imageInspectionType: "Image",
    //     },
    //   }),
    // });

    const body = {
      amount: price,
        transactionData: {
          // package: "string",
          auction: auctionId,
          // inventories: ["string"],
          type: "TOKEN",
          imageInspectionFormat: "Single",
          imageInspectionType: "Image",
        },
    }

    const data = await makePayment(body,user);

    if (data.Url) {
      window.location.href = data.Url;
    }
    if(data.error){
      setNotifyMessage(data.error.message, "error");
    }
    // if (!response.ok) {
    //   setNotifyMessage(data.message, "error");
    //   throw new Error(data.message);
    // }
    // setNotifyMessage("Payment done", "success");
    console.log("payment data", data);
    return data.data;
  });
  return (
    <>
      {navigationComponent}
      <Modal onClose={onClose} className={classes.modal}>
        <ModalHeader className={classes.header} onClose={onClose} title="" />
        <div className={classes.modalOuter}>
          <h2>
            Auction Name : {name}({auctionNo})
          </h2>
          <h4>
            Please make the payment to get the unique id for attending the
            physical auction
          </h4>

          <Button
            className={classes.paymentSubmitButton}
            onClick={createPayment}
            loading={isLoading}
            disabled={isLoading}
            option="colored"
          >
            <h1>
              Pay &nbsp;<span>₹ {price}</span>
            </h1>
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default PhysicalAuctionPayment;
