import { API_BASE_URL } from '../configs/AppConfig';

export const fetchUserData = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}participant/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Something went wrong');
    }

    return await response.json();
  } catch (err) {
    console.log(err);
  }
};
