import Modal from "components/UI/modal/Modal";
import ModalHeader from "components/UI/modal/ModalHeader";
import { useMutation } from "@tanstack/react-query";

import classes from "./AuctionBuyer.module.css";
import { useSelector } from "react-redux";
import useNotificationBar from "hooks/useNotificationBar";
import { API_BASE_URL } from "configs/AppConfig";
import { useEffect, useState } from "react";
import Button from "components/UI/button/Button";

const AuctionBuyer = ({ onClose }) => {
  const { user } = useSelector((state) => state.user);
  const { auctionData } = useSelector((state) => state.auction);
  console.log("redux auctionData", auctionData);
  const { setNotifyMessage, navigationComponent } = useNotificationBar();
  const [requestStatus, setRequestStatus] = useState(null);
  const [gotStatus, setGotStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const requestStatusHandler = async () => {
      const data = await checkAuctionRequestSent();
      if (data.data) {
        setRequestStatus(data?.data[0]?.status);
      }
      setGotStatus(true);
    };
    requestStatusHandler();
  }, []);

  const { mutateAsync: checkAuctionRequestSent } = useMutation(async () => {
    const response = await fetch(
      `${API_BASE_URL}auction_request/get/all?auctionId=${auctionData?.data?._id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );

    const data = await response.json();
    return data;
  });

  const { mutateAsync: sendRequestAuctionAccess } = useMutation(async () => {
    const response = await fetch(`${API_BASE_URL}auction_request/create`, {
      method: "POST",
      body: JSON.stringify({ auctionId: `${auctionData?.data?._id}` }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      setNotifyMessage(data.message, "error");
      throw new Error(data.message);
    }

    setNotifyMessage("Request posted successfully", "success");
    return data.data;
  });

  const requestAccessHandler = async () => {
    setIsLoading(true);
    const requestResponse = await sendRequestAuctionAccess();
    if (requestResponse) {
      console.log("data response of Access request: ", requestResponse);
      setRequestStatus(requestResponse?.status);
    }
    setIsLoading(false);
  };

  return (
    <Modal onClose={onClose} className={classes.modal}>
      <ModalHeader className={classes.header} onClose={onClose} title="" />
      <div className={classes.wrapper}>
        <h1>
          You don’t have access to this auction because please contact with
          Auction manager
        </h1>

        {gotStatus && !requestStatus && (
          <div className={classes.access}>
            <Button
              className={classes.clickhere}
              onClick={requestAccessHandler}
              disabled={isLoading}
            >
              Click here
            </Button>
            <h2> to request for access</h2>
          </div>
        )}
        {gotStatus && requestStatus === "Pending" && (
          <div className={classes.access}>
            <h2>Access request sent</h2>
          </div>
        )}
        {gotStatus && requestStatus === "Rejected" && (
          <div className={classes.access}>
            <h2>Access request rejected</h2>
          </div>
        )}
      </div>
      {navigationComponent}
    </Modal>
  );
};

export default AuctionBuyer;
