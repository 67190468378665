import { createPortal } from 'react-dom';

import NavbarList from '../navbar-list/NavbarList';
import InputForm from 'components/forms/input/InputForm';

import classes from './SideNavbar.module.css';

const SideNavbar = ({ onClose }) => {
  const portalElement = document.getElementById('overlays');
  const onSubmitSearchFormHandler = (value) => {};

  return createPortal(
    <div className={classes.sideNavbar}>
      <InputForm
        name="search"
        placeholder="Search"
        onSubmit={onSubmitSearchFormHandler}
        className={classes.searchInput}
      />
      <NavbarList isSide onClose={onClose} className={classes.navbarList} />
    </div>,
    portalElement
  );
};

export default SideNavbar;
