import { createPortal } from 'react-dom';

import classes from './NotificationBar.module.css';

const msgType = {
  success: classes.success,
  error: classes.error
};

const NotificationBar = ({ type, msg }) => {
  const portalElement = document.getElementById('overlays');

  return createPortal(
    <div className={`${classes.notificationBar} ${msgType[type]}`}>
      <p> {msg} </p>
    </div>,
    portalElement
  );
};

export default NotificationBar;
