import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from 'store-redux/modal-slice';
import { NavbarLinks } from 'helpers/constants';

import InputForm from 'components/forms/input/InputForm';
import HumburgerButton from 'components/UI/button/HumburgerButton';
import Signup from 'components/modal/sign-up/Signup';
import SignupDetails from 'components/modal/sign-up/SignupDetails';
import SignIn from 'components/modal/sign-in/SignIn';
import Button from 'components/UI/button/Button';

import NavbarList from './navbar-list/NavbarList';
import NavbarLogo from './navbar-logo/NavbarLogo';
import SideNavbar from './side-navbar/SideNavbar';
import NavbarListIcon from './navbar-list-icon/NavbarListIcon';

import classes from './Navbar.module.css';
import ResetPassword from 'components/modal/reset-password/ResetPassword';

// Navbar component => the Parent Component for the Navbar elements
// all the navbar related components are child for this component
let emailValue = '';
const Navbar = () => {
  const dispatch = useDispatch();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: 'signin'
      })
    );
  };

  const setSignupDetailsModalHandler = (value) => {
    emailValue = value;
    dispatch(
      modalActions.changeModalComponent({
        modalType: 'signupDetails'
      })
    );
  };

  const setSideNavbarIsOpenHandler = () => {
    if (modalType === 'sidenav') {
      dispatch(modalActions.toggleModal());
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: 'sidenav'
        })
      );
    }
  };

  const closeModalHandler = () => {
    if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  };

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  const renderBrowserNavButtons = () => {
    if (!isLoggedIn) {
      return (
        <Button type="button" option="colored" onClick={setSigninModalHandler}>
          Sign in
        </Button>
      );
    }

    return <NavbarListIcon setSigninModalHandler={setSigninModalHandler} />;
  };

  const renderModal = () => {
    if (modalIsVisible) {
      if (modalType === 'signup') {
        return (
          <Signup
            showSignupDetailsModal={setSignupDetailsModalHandler}
            onClose={toggleModalHandler}
          />
        );
      }

      if (modalType === 'signupDetails') {
        return (
          <SignupDetails emailValue={emailValue} onClose={toggleModalHandler} />
        );
      }

      if (modalType === 'signin') {
        return <SignIn onClose={toggleModalHandler} />;
      }

      if (modalType === 'resetpassword') {
        return <ResetPassword onClose={toggleModalHandler} />;
      }

      if (modalType === 'sidenav') {
        return <SideNavbar onClose={toggleModalHandler} />;
      }
    }

    return;
  };

  return (
    <div className={`${classes.mainNavbar}`}>
      <NavbarLogo onClick={closeModalHandler} />
      <div className={classes.navbarList}>
        <NavbarList navList={NavbarLinks} />
        {/* <InputForm
          name="search cars"
          placeholder="Search for cars (ex.  BMW, Audi)"
          onSubmit={() => {}}
        /> */}
        {renderBrowserNavButtons()}
      </div>
      <HumburgerButton
        className={classes.humburgerButton}
        onClick={setSideNavbarIsOpenHandler}
        open={modalIsVisible && modalType === 'sidenav'}
      />
      {renderModal()}
    </div>
  );
};

export default Navbar;
