import classes from "./ArrowButton.module.css";

const arrowDirection = {
  left: classes.left,
  right: classes.right,
};

const ArrowButton = ({ className, side, onClick }) => {
  return (
    <button onClick={onClick} className={`${classes.arrowButton} ${className}`}>
      {side === "right" && `${" "}Next`}
      {/* <i className={`${classes.arrow} ${arrowDirection[side]}`}></i> */}
      {side === "left" && "Previous"}
    </button>
  );
};

export default ArrowButton;
