export const AuctionsDataKey = 'auctions';
export const AuctionsPhysicalDataKey = 'auctionsPhysical';
export const AuctionsInventoryDataKey = 'auctionsInventory';

export const AuctionsCloseInventoryDataKey = 'auctionsCloseInventory';
export const AuctionImageInspectionDataKey = 'auctionImageInspection';

export const ConstantsDataKey = 'constants';
export const BiddingsDataKey = 'biddings';
export const HighestBidDataKey = 'highestBid';
export const CommentsDataKey = 'comments';
export const YourBidsDataKey = 'yourBids';
export const ApproveBidsDataKey = 'approveBids';
export const WatchListInventoryDataKey = 'watchlistInventory';
export const WatchListAuctionsDataKey = 'watchlistAuctions';
export const WishlistDataKey = 'wishlist';
export const WinsDataKey = 'wins';
export const RegistrationDataKey = 'registrations';
export const SettingsDataKey = 'settings';

export const BannersDataKey = 'banners';

export const MakesDataKey = 'makes';
export const ModelsDataKey = 'models';
export const StatesDateKey = 'states';
export const CitiesDateKey = 'cities';
export const regionDateKey = 'regions';
export const VehiclesTypesDateKey = 'VehiclesTypes';
export const PreferredClientsDataKey = 'PreferredClients';
export const PackagesDataKey = 'Packages';

export const AuctionConstantsDataKey = 'auctionConstants';

export const ProfileDataKey = 'profile';

export const PublicInformation = 'publicInformation';
