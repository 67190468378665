import { useRef } from 'react';

import classes from './InputForm.module.css';

const InputForm = ({ className, name, placeholder, onSubmit }) => {
  const searchInputRef = useRef();

  const onSubmitFormHandler = (event) => {
    event.preventDefault();
    if (searchInputRef.current.value.trim() === '') {
      return;
    }
    onSubmit(searchInputRef.current.value);
    searchInputRef.current.value = '';
  };

  return (
    <form onSubmit={onSubmitFormHandler} className={className}>
      <input
        type="search"
        name={name}
        placeholder={placeholder}
        ref={searchInputRef}
        className={classes.searchInput}
      />
      <button type="submit" className={classes.submitButton}></button>
    </form>
  );
};

export default InputForm;
