import Modal from 'components/UI/modal/Modal'
import ModalHeader from 'components/UI/modal/ModalHeader'
import React from 'react'
import classes from './AuctionToken2.module.css'

function AuctionTokenTwo({onClose, name, auctionNo, userName, phoneNumber}) {

    const date = new Date();
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0');
const year = date.getFullYear();

const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal onClose={onClose} className={classes.modal}>
    <ModalHeader
    className={classes.header}
    onClose={onClose}
    title=""
    />
    <div className = {classes.modalOuter}>
    <h1 className = {classes.auctionName}>Auction Name :</h1>
    <h1 className={classes.auctionNo}>{name} ({auctionNo})</h1>
    <div className = {classes.tokenCircle}>
        <h1>Token</h1>
        <h4>1</h4>
    </div>
    </div>
    </Modal>
  )
}

export default AuctionTokenTwo
