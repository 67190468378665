const StartIcon = ({ className, onClick }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M18.2845 6.43422C18.2265 6.27271 18.12 6.13305 17.9797 6.03431C17.8393 5.93558 17.6719 5.88257 17.5003 5.88255H11.817L9.95365 0.558379C9.89663 0.395218 9.79028 0.253827 9.64933 0.153785C9.50839 0.0537423 9.33983 0 9.16699 0C8.99415 0 8.82558 0.0537423 8.68464 0.153785C8.5437 0.253827 8.43735 0.395218 8.38032 0.558379L6.51698 5.88255H0.833649C0.661943 5.88248 0.494409 5.93546 0.353958 6.03423C0.213506 6.13301 0.106995 6.27276 0.0489901 6.43437C-0.00901494 6.59598 -0.0156818 6.77156 0.0299012 6.93711C0.0754841 7.10265 0.171091 7.25008 0.303648 7.35922L4.72532 11.0009L2.54698 17.2251C2.49141 17.386 2.48649 17.5602 2.5329 17.724C2.5793 17.8879 2.67479 18.0336 2.80652 18.1416C2.93825 18.2495 3.09988 18.3145 3.26967 18.3278C3.43945 18.3411 3.60923 18.302 3.75615 18.2159L9.16699 15.0359L14.5778 18.2159C14.7249 18.3024 14.8951 18.3417 15.0653 18.3284C15.2355 18.3151 15.3974 18.2499 15.5293 18.1415C15.6612 18.0332 15.7567 17.8869 15.8028 17.7226C15.8488 17.5582 15.8433 17.3837 15.787 17.2226L13.6087 11.0009L18.0303 7.35922C18.1628 7.25 18.2584 7.10253 18.3039 6.93696C18.3493 6.77138 18.3426 6.5958 18.2845 6.43422ZM12.1003 10.0842C11.9689 10.1924 11.8738 10.3383 11.8278 10.5022C11.7817 10.666 11.787 10.8401 11.8428 11.0009L13.462 15.6326L9.58949 13.3542C9.46143 13.2789 9.31556 13.2392 9.16699 13.2392C9.01842 13.2392 8.87255 13.2789 8.74449 13.3542L4.87198 15.6326L6.49115 11.0059C6.54699 10.8451 6.55225 10.671 6.50621 10.5072C6.46018 10.3433 6.36506 10.1974 6.23365 10.0892L3.15615 7.54922H7.10782C7.28055 7.54928 7.44904 7.49567 7.58997 7.39579C7.7309 7.29591 7.83731 7.15471 7.89449 6.99172L9.16699 3.35588L10.4395 6.99172C10.4967 7.15471 10.6031 7.29591 10.744 7.39579C10.8849 7.49567 11.0534 7.54928 11.2262 7.54922H15.1778L12.1003 10.0842Z" />
    </svg>
  );
};

export default StartIcon;
