import { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import { API_BASE_URL } from "../../../../configs/AppConfig";
import { AuctionsPhysicalDataKey } from "../../../../helpers/queriesDataKeys";
import { AuctionTime1, AuctionTime2, AuctionTime3 } from "../../../../helpers/constants";

import AuctionItem from "./AuctionItem";

import classes from "./Auctions.module.css";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import PhysicalAuctionPayment from "components/page-content/dashboard-content/dashboard-ui/auctions/PhysicalAuctionPayment/PhysicalAuctionPayment";
import "./Auctions.css";
import { modalActions } from "store-redux/modal-slice";
import { useDispatch, useSelector } from "react-redux";
import AuctionBuyer from "components/modal/auction-buyer/AuctionBuyer";
import AuctionTimesBtns from "components/UI/button/auction-times/AuctionTimesBtns";
import ArrowButton from "components/UI/button/ArrowButton";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import AuctionItems from "./AuctionItem";
import AuctionTimesBtnsTokens from "components/UI/button/auction-times-token/AuctionTimesBtns";

const AuctionsList = () => {
  const [auctionTime, setAuctionTime] = useState(AuctionTime1);
  const dispatch = useDispatch();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [flag, setFlag] = useState(false);

  let sliderMethods = null;

  const onNextSlideHandler = () => {
    if (!sliderMethods) {
      return;
    }
    sliderMethods.slickNext();
  };

  const onPrevSlideHandler = () => {
    if (!sliderMethods) {
      return;
    }
    sliderMethods.slickPrev();
  };

  useEffect(() => {
    setFlag(!flag);
  }, []);

  const {
    isLoading,
    isError,
    data: auctions,
    error,
  } = useQuery([AuctionsPhysicalDataKey, auctionTime, flag], async () => {
    const response = await fetch(
      `${API_BASE_URL}token/get/all?timeStatus=${auctionTime}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }

    return data.data;
  });

  //   const { data: auctions, isLoading, error } = useQuery(
  //     [],
  //     async () => {
  //       const response = await fetch(`${API_BASE_URL}constants/participant`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${user.sessionToken}`,
  //         },
  //       });
  //       const data = await response.json();
  //       if (!response.ok) {
  //         throw new Error(data.message);
  //       }

  //       return data.data;
  //     }
  //   );

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src="/img/arrow-left.png"
          alt="Previous"
          // style={{
          //   width: "40px",
          //   height: "40px",
          //   cursor: "pointer",
          //   marginLeft: "-20px",
          // }}
          className={classes.prevImage}
        />
      </div>
    );
  };

  // Custom Next Arrow component
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src="/img/arrow-left.png"
          alt="Next"
          // style={{
          //   width: "40px",
          //   height: "40px",
          //   cursor: "pointer",
          //   marginLeft: "-2px",
          //   transform: "scale(-1)",
          // }}
          className={classes.nextImage}
        />
      </div>
    );
  };

  const toggleModalHandler = () => {
    // toggle the visibility of the Modal
    dispatch(modalActions.toggleModal());
  };

  let sliderSettings = {
    // infinite: true,
    infinite: auctions?.length > 1,
    slidesToShow: auctions ? (auctions.length > 2 ? 3 : 2) : 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplaySpeed: 6000,
    swipeToSlide: true,
    margin: 10,
    centerMode: true, // Enable center mode
    centerPadding: "-40px", // Adjust the padding to control the margin size
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1.2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  const notInListHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "auctionbuyer",
      })
    );
  };

  return (
    <>
      <div className={classes.auctionsContainer}>
        <div className={classes.header}>
          <AuctionTimesBtnsTokens
            numberOfActiveBtn={auctionTime === AuctionTime1 ? 1 :(auctionTime === AuctionTime2 ? 2 : 3)}
            onClick1={() => setAuctionTime(AuctionTime1)}
            onClick2={() => setAuctionTime(AuctionTime2)}
            onClick3={() => setAuctionTime(AuctionTime3)}
          />
          <section className={classes.btns}>
            {/* <Link to={`more`}> View More </Link> */}
            <div>
              <ArrowButton side={"left"} onClick={onPrevSlideHandler} />
              <ArrowButton side={"right"} onClick={onNextSlideHandler} />
            </div>
          </section>
        </div>
        {!isLoading && !!error && (
          <ErrorLabel option="green">
            No Physical Auctions Available for now. Will Update soon!
          </ErrorLabel>
        )}
        {isLoading && !error && <LoadingSpinner />}
        {!isLoading && !error && (
          <div className={classes.sliderOuter}>
            <Slider
              arrows={false}
              ref={(slider) => (sliderMethods = slider)}
              {...sliderSettings}
              className={classes.auctionList}
            >
              {auctions.map((auction) => (
                <AuctionItems
                  key={auction.auction._id}
                  id={auction.auction._id}
                  name={auction.auction.name}
                  auctionNo={auction?.auction?.auctionNo}
                  vehicleCount={auction.auction?.inventoryCount}
                  date={auction.auction?.startTimestamp}
                  vehicleType={auction.auction?.vehicleType}
                  imgSrc={
                    auction.auction?.image
                      ? auction.auction?.image
                      : "img/no-image.jpg"
                  }
                  description={{
                    vehicleType: auction.auction?.vehicleType?.name,
                    city: auction.auction?.city?.name,
                    region: auction.auction?.region?.name,
                  }}
                  registrationPrice={auction?.auction?.registrationPrice}
                  notInListHandler={notInListHandler}
                  singlePrice={auction?.auction?.singlePrice}
                  bulkPrice={auction?.auction?.bulkPrice}
                  startTimestamp={auction?.auction?.startTimestamp}
                  endTimestamp = {auction?.auction?.endTimestamp}
                  tokenNumber={auction?.tokenNumber}
                  createdAt = {auction?.createdAt}
                  mobileNumber = {auction?.auction?.auctionManagers[0]?.contact}
                />
              ))}
            </Slider>
          </div>
        )}
      </div>
      {modalIsVisible && modalType === "auctionbuyer" && (
        <AuctionBuyer onClose={toggleModalHandler} />
      )}
    </>
  );
};

export default AuctionsList;
