import { NavLink } from 'react-router-dom';

import { dashboardNavLinks } from '../../../../helpers/constants';

import classes from './Navbar.module.css';

export const NavbarItem = ({ pageLink, children, end }) => {
  return (
    <li className={classes.navbarItem}>
      <NavLink
        to={pageLink}
        end={end}
        className={({ isActive }) => (isActive ? classes.active : undefined)}
      >
        {children}
      </NavLink>
    </li>
  );
};

const Navbar = () => {
  return (
    <ul className={classes.navbar}>
      {dashboardNavLinks.map((link) => (
        <NavbarItem
          key={link.title}
          pageLink={link.to}
          end={link.title !== 'WatchList'}
        >
          {link.title}
        </NavbarItem>
      ))}
    </ul>
  );
};

export default Navbar;
